import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { FolderRemoveIcon } from "@heroicons/react/solid"
import rematter from "../images/sustainability/ReMatter.svg"
import Zoom from "react-reveal/Zoom"
import ReactPlayer from "react-player"

const Sustainability = ({ data }) => {
  const images = {
    header: convertToBgImage(getImage(data.header)),
    does: convertToBgImage(getImage(data.does)),
    environment: convertToBgImage(getImage(data.environment)),
    sustain: convertToBgImage(getImage(data.sustain)),
    equity: convertToBgImage(getImage(data.equity)),
    circularity: convertToBgImage(getImage(data.circularity)),
    ReMatter: getImage(data.ReMatter),
    nckn: getImage(data.nckn),
    Rainforest: getImage(data.Rainforest),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
  }

  const change = [
    {
      image: images.circularity,
      topic: "Product longevity & circularity ",
      desc: "As part of our conscious product design efforts, we intend to reduce our impact on the environment. We hope to mitigate the environmental damages caused by fast fashion and consumerism by extending the longevity of our products. Our teams actively work on enhancing modularity and easy dis-assembly of electronic elements to support recycling efforts. ",
    },

    {
      image: images.environment,
      topic: "Preserving our environment",
      desc: " Reforestation and preservation of existing forests is of utmost priority to us to support carbon netting off efforts. We place an equally prominent emphasis on enabling wildlife conservation through our technological infrastructure, especially for endangered species in Sri Lanka such as Leopards and Elephants.",
    },
    {
      image: images.equity,
      topic: "Equality & Inclusivity",
      desc: "Our emphasis lies in ensuring STEM education is more accessible and equitable to inspire the next generation of innovators, especially from economically lagging regions in Sri Lanka.  We aim to create an advocacy coalition of partners committed to championing women in STEM. ",
    },
  ]

  return (
    <Layout>
      <Seo title="Sustainability" />
      <section>
        <BackgroundImage {...images.header} className="h-header bg-salmon">
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex justify-end w-full">
                  <GatsbyImage
                    image={images.ReMatter}
                    className="mx-auto md:mx-0 w-3/4 md:w-1/4"
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="">
          <div className="container mx-auto pt-10 px-4 sm:pt-20 sm:px-6 md:px-8">
            <Fade bottom>
              <div className="text-mint mb-4  text-center font-bold text-xl sm:text-2xl">
                Making Sustainable Value Matter
              </div>

              <div className="text-lg md:text-2xl font-light text-center max-w-5xl mx-auto leading-relaxed mt-2">
                At Softmatter, we are committed to creating sustainable value
                for all our stakeholders. Our designs, processes, and materials
                are tailored to build products which are well-crafted, robust,
                with minimal impact on our planet. Our manufacturing facilities
                follow the most advanced eco-efficient practices in the
                industry. Furthermore, we invest in the development of our
                employees, to achieve our 2030 vision towards gender parity,
                enhancing accessibility and sustainable living.
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="container mx-auto  px-4 sm:pt-20 sm:px-6 md:px-8 ">
            <Fade bottom>
              <div className="text-mint uppercase text-center font-firs-thin text-xl sm:text-2xl">
                OUR FOCUS FOR
              </div>

              <div className="text-4xl sm:text-8xl font-light text-center max-w-5xl mt-2 mx-auto leading-relaxed text-mint">
                CHANGE
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="container mx-auto pt-10 pb-20 px-4 sm:pt-20 sm:px-6 md:px-8">
            <Fade bottom>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
                {change.map((item, index) => {
                  return (
                    <div key={index}>
                      <BackgroundImage
                        {...item.image}
                        className="py-24 sm:py-40  h-full relative group cursor-pointer"
                      >
                        <div className="flex items-center opacity-100 h-full text-center text-white justify-center w-full text-xl sm:text-2xl font-firs-semibold">
                          <div>{item.topic}</div>
                        </div>
                        <div className="absolute top-0 bg-dusk w-full h-full group-hover:opacity-100 opacity-0 duration-300">
                          <div className="text-mint px-4 sm:px-8 py-2 sm:py-4 flex justify-center items-center h-full text-center">
                            <div>{item.desc}</div>
                          </div>
                        </div>
                      </BackgroundImage>
                    </div>
                  )
                })}
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="bg-mint">
            <div className="container mx-auto py-16">
              <div className="text-xl md:text-2xl text-dusk font-firs-medium">
                Does it Matter?
              </div>
              <div className="mt-6 md:mt-36">
                <div className=" md:items-center">
                  <div className="md:w-1/2 pb-5">
                    <GatsbyImage image={images.nckn} className="w-full" />
                  </div>
                  <div className="md:w-2/3 mt-6 md:mt-0">
                    <div className="text-white text-xl md:text-2xl font-firs-regular max-w-xl">
                      We're driving science, technology, and innovation to help
                      meet our 2030 ambition to strive for positive impact and
                      change.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <BackgroundImage
              {...images.does}
              className="py-40 md:py-80 bg-cover"
            ></BackgroundImage>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk">
          <div className="container mx-auto py-10 sm:py-16 md:py-20 lg:py-24">
            <Fade bottom cascade>
              <div className="pb-10 sm:pb-16 md:pb-20 lg:pb-24">
                <div className="text-center text-4xl sm:text-5xl lg:text-6xl mb-4 text-mint">
                  Our Programs
                </div>
                <div className="text-base sm:text-2xl lg:text-2xl mt-2 text-center max-w-4xl mx-auto text-white">
                  We have twelve commitments for 2030 which we will work towards
                  as we move forward in our journey to sustainable systemic
                  change.
                </div>
              </div>
            </Fade>
            <div className="flex justify-center ">
              <div className="">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=1NP6IdT4ZaU"
                  width="100%"
                />
              </div>
            </div>
            <Fade bottom cascade>
              <div className="pb-10 sm:pb-16 md:pb-20 lg:pb-24 mt-10 sm:mt-16 md:mt-20 lg:mt-24">
                <div className="text-center text-3xl sm:text-4xl lg:text-4xl mb-4 text-mint">
                  Softmatter x Rainforest Protectors of Sri Lanka
                </div>
                <div className="text-base sm:text-lg lg:text-2xl mt-2 text-center max-w-4xl mx-auto text-white">
                  The Softmatter team engaged in a reforestation exercise to
                  help reforest 2 acres of land in Rakwana Hills in Sri Lanka.
                  This is an ecologically important habitat bordering the
                  Sinharaja World Heritage Rainforest.
                </div>
                <div className="text-base sm:text-lg lg:text-2xl mt-2 text-center max-w-4xl mx-auto text-white">
                  This is our first step towards preserving and restoring
                  habitats to help restore biodiversity.
                </div>
              </div>
            </Fade>

            <div className="flex flex-col sm:flex-row justify-center items-center gap-6 sm:gap-10 md:gap-20 lg:gap-20">
              <div className="w-full sm:w-2/3">
                <div className="w-full flex justify-center">
                  <GatsbyImage
                    image={images.Rainforest}
                    className="w-1/2 sm:w-1/3 lg:w-1/4"
                  />
                </div>
                <div className="text-white text-center  mt-4 text-base sm:text-lg lg:text-xl">
                  We partnered with Rainforest Protectors of Sri Lanka, which is
                  a non-profit environmental organization with a mission to
                  protect the last remaining rainforests and threatened
                  ecosystems in Sri Lanka, including Sinharaja and Kanneliya
                  rainforests, through conservation projects and environmental
                  activism primarily driven through volunteer support.
                </div>
              </div>
              <div>
                <div>
                  <div className="text-mint  text-lg sm:text-xl lg:text-2xl">
                    Size of Land Reforested:
                  </div>
                  <div className="text-white text-3xl sm:text-4xl lg:text-5xl mt-2">
                    2 acres
                  </div>
                </div>
                <div className="mt-4 sm:mt-8">
                  <div className="text-mint text-lg sm:text-xl lg:text-2xl max-w-sm mt-4 sm:mt-6">
                    Expected Carbon Sequestration Rate in Our Reforested Site:
                  </div>
                  <div className="text-white text-3xl sm:text-4xl lg:text-5xl">
                    50%
                  </div>
                </div>
              </div>
            </div>

            <Link to="/">
              <div className="flex justify-center w-full mt-10 sm:mt-16 md:mt-20 lg:mt-24">
                <a href="https://rainforestprotectors.org/rainforest/index.aspx">
                  <button className="uppercase px-4 py-3 border border-white rounded-full text-white mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                    Learn More
                  </button>
                </a>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <section>
        <div className="">
          <BackgroundImage
            {...images.sustain}
            className="py-10 sm:py-20 md:py-24 lg:py-28"
          >
            <div className="container mx-auto py-6 sm:py-16">
              <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-end sm:items-center">
                <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/2 text-center sm:text-right">
                  <div className="text-2xl sm:text-4xl text-white max-w-xl sm:max-w-2xl">
                    Our Quest for Sustainable Materials
                  </div>
                  <div className="flex justify-center sm:justify-end w-full mt-4 sm:mt-6">
                    <a href="shop/sustainable-development-kit">
                      <button className="uppercase px-4 py-3 border border-white rounded-full text-white hover:bg-mint/20 cursor-pointer duration-200">
                        Learn More
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="bg-dusk">
          <div className="container mx-auto py-6 sm:py-10 md:py-16 lg:py-20">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 md:gap-10 lg:gap-12">
              <div className="text-mint">
                <div className="text-2xl sm:text-4xl font-firs-medium">
                  MAS Holdings
                </div>
                <div className="text-xl sm:text-2xl mt-2">Plan for Change</div>
                <div className="flex justify-center sm:justify-start mt-4 sm:mt-6">
                  <a href=" https://www.masholdings.com/plan-for-change/">
                    <button className="uppercase px-4 py-3 border border-white rounded-full text-white hover:bg-mint/20 cursor-pointer duration-200">
                      Learn More
                    </button>
                  </a>
                </div>
              </div>

              <div className="text-mint text-2xl sm:text-4xl font-firs-medium">
                <div className="">Product.</div>
                <div className="mt-2">People.</div>
                <div className="mt-2">Planet.</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full ">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 lg:px-0 px-5 text-dusk">
                <div className=" text-center   text-5xl sm:text-6xl mb-4 ">
                  Connect with Softmatter
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 rounded-3xl bg-mint  hover:bg-mint/90 duration-200 text-black text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query SustainabilityImages {
    header: file(relativePath: { eq: "sustainability/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    does: file(relativePath: { eq: "sustainability/does.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    environment: file(relativePath: { eq: "sustainability/environment.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    equity: file(relativePath: { eq: "sustainability/equity.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    circularity: file(relativePath: { eq: "sustainability/circularity.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    sustain: file(relativePath: { eq: "sustainability/sustain.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    nckn: file(relativePath: { eq: "sustainability/nckn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    ReMatter: file(relativePath: { eq: "sustainability/ReMatter.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Rainforest: file(relativePath: { eq: "sustainability/Rainforest.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Sustainability
